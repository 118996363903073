import { Link, Typography } from "@mui/material"
import { PanelWithHeader } from "../PanelWithHeader"
import { LinkComponent } from "./LinkComponent"
import { RowItem } from "./RowItem"

/**
 * Benefits and Perks panel on Resources and Tools page
 */
export const BenefitsAndPerks = () => {
    return <PanelWithHeader header="Benefits & Perks" sx={{ maxWidth: '324px', height: 'max-content' }}>
            <RowItem>
                <LinkComponent header href="https://aus-pdf.s3.us-east-1.amazonaws.com/phenomenal_start/admin_payroll_calendar.pdf">
                    Admin Bi-Weekly Payroll Calendar
                </LinkComponent>
            </RowItem>

            <RowItem>
                <LinkComponent header href="https://applications.aus.com/Vacation.Website/Pages/SalaryAttendance/Login.aspx">
                    Salary Attendance Sheets
                </LinkComponent>
            </RowItem>

            <RowItem>
                <LinkComponent header href="https://www.allieduniversalbenefits.com/" sx={{ mb: '5px' }}>
                    Benefits Information
                </LinkComponent>

                <Typography fontSize="12px">
                    For further assistance you can call our benefits group by dialing (888) 670-7106 or emailing <Link underline="none" href="mailto:benefits@aus.com">benefits@aus.com</Link>
                </Typography>
            </RowItem>

            <RowItem>
                <LinkComponent header href="https://ehub.aus.com/ehub/Account/Login?ReturnUrl=%2Fehub%2F" sx={{ mb: '5px' }}>
                    eHub
                </LinkComponent>

                <Typography fontSize="12px">
                    eHub is an online resource and can be viewed through the mobile app or by using your web browser. eHub allows you to keep track of your personal and job information.
                </Typography>
            </RowItem>

            <RowItem>
                <LinkComponent header href="https://pslogin.perkspot.com/login?communityId=956" sx={{ mb: '5px' }}>
                    Employee Discount Program
                </LinkComponent>

                <Typography fontSize="12px">
                    As an Allied Universal® employee, you have access to a variety of programs that can help save you money and provide important assistance with everyday needs.
                </Typography>
            </RowItem>

            <RowItem>
                <LinkComponent header href="http://www.guidanceresources.com/" sx={{ mb: '5px' }}>
                    Employee Assistance Program
                </LinkComponent>

                <Typography fontSize="12px" marginBottom="20px">
                    Sometimes life can feel overwhelming. 
                    <br/>
                    It doesn't have to. 24/7 support.
                </Typography>

                <Typography fontSize="12px" color="#0000008F">
                    (To log in Please select the “Register” tab and enter the Organization Web ID: “Allied”. You can also call for assistance at (800) 344-9752.)
                </Typography>
            </RowItem>
    </PanelWithHeader>
}