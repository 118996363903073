import Typography from '@mui/material/Typography';
import { PanelWithHeader } from '../PanelWithHeader';
import { LinkComponent } from '../resources-and-tools/LinkComponent';

/**
 * General Directory panel for "Get Connected" page
 */
export const GeneralDirectory = () => (
  <PanelWithHeader header="General Directory" sx={{ maxWidth: '385px', mb: 4 }}>
    <Typography
      p="16px"
      sx={{ color: 'rgba(0, 0, 0, 0.72)', fontSize: '12px', fontWeight: 400, lineHeight: '24px' }}>
      The{' '}
      <LinkComponent
        sx={{ color: 'rgba(0, 95, 144, 1)', fontSize: '12px', fontWeight: 600, lineHeight: '24px' }}
        href="https://allieduniversalservices.sharepoint.com/sites/HR/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FHR%2FShared%20Documents%2FGeneral%20Directory%2Epdf&parent=%2Fsites%2FHR%2FShared%20Documents">
        General Directory
      </LinkComponent>{' '}
      provides a quick reference to phone numbers and/or email addresses for common topics.
    </Typography>
  </PanelWithHeader>
);